// extracted by mini-css-extract-plugin
export var careers = "PageDefault-module--careers--QBOby";
export var colorGreen = "PageDefault-module--color-green--lUVwC";
export var colorGreenLight = "PageDefault-module--color-green-light--tbke9";
export var colorOrange = "PageDefault-module--color-orange--70BVl";
export var colorPink = "PageDefault-module--color-pink--PQ2ck";
export var colorPurple = "PageDefault-module--color-purple--YylM3";
export var core = "PageDefault-module--core--Uxi3m";
export var fontCustom = "PageDefault-module--font-custom--tTOks";
export var functionalSkills = "PageDefault-module--functional-skills--JXFRk";
export var green = "PageDefault-module--green--pFpJy";
export var greenLight = "PageDefault-module--green-light--WlKAm";
export var imgText = "PageDefault-module--img-text--kdMR+";
export var imgWhy = "PageDefault-module--img-why--+MBpB";
export var largeFont = "PageDefault-module--large-font--00SIG";
export var largeVersion = "PageDefault-module--large-version--kChtf";
export var littleBox = "PageDefault-module--little-box--S2l9m";
export var littleFont = "PageDefault-module--little-font--4vjuw";
export var margin = "PageDefault-module--margin--vWIj7";
export var marginTop = "PageDefault-module--marginTop--jX048";
export var notFound = "PageDefault-module--not-found--eWneX";
export var oneBox = "PageDefault-module--one-box--1lz9P";
export var orange = "PageDefault-module--orange--s5nZ3";
export var paddingTop = "PageDefault-module--paddingTop--fPJRR";
export var paddingX = "PageDefault-module--padding-x--uu241";
export var purple = "PageDefault-module--purple--ydHiX";
export var round = "PageDefault-module--round--TkMSn";
export var skillsBox = "PageDefault-module--skillsBox--PJnuS";
export var structureBox = "PageDefault-module--structure-box--noKgB";
export var supportBox = "PageDefault-module--support-box--Hmub5";
export var twoBox = "PageDefault-module--two-box--n2r2w";
export var widthCustom = "PageDefault-module--width-custom--gjKXR";