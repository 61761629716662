import React, { useState, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { useContext } from '~/hooks'
import { Head, Footer, Link } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/PageDefault.module.styl'
import * as sd from '~/assets/styl/EntregaveisDetalheBusca.module.styl'
import cn from 'classnames'

const ResultadoBusca = ({ location }: PageProps & { '*': string }) => {
  const { setLoading } = useContext()
  const { searchs } = useApi(
    {
      searchs: [],
    },
    'page-search'
  )

  const [url, setUrl] = useState('')
  useEffect(() => {
    const handleSlug = location.pathname.split('/')
    setUrl(handleSlug[2])
  })

  const newArray = searchs.map((item) => {
    const filter = item.items.filter(({ slug }) => {
      return new RegExp('(' + url + ')', 'gi').test(slug)
    })
    return {
      id: item.id,
      name: item.name,
      slug: item.slug,
      items: filter,
    }
  })

  const foundItems = newArray.map(({ items }) => {
    return items.length
  })

  let total = 0
  for (let i = 0; i < foundItems.length; i++) {
    total += foundItems[i]
  }

  setLoading(!foundItems)

  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container no-padding-mobile">
          <h1>Resultado da sua busca</h1>
          <div className={sd.core}>
            <div className={cn(sd.containerPrincipal, sd.customPad)}>
              {total > 0 ? (
                <p className={sd.searchInfo}>
                  {total > 1 ? 'Foram encontrados' : 'Foi encontrado'}{' '}
                  <strong>
                    {total} {total > 1 ? 'resultados' : 'resultado'}
                  </strong>{' '}
                  <br />
                  para a sua busca: &quot;
                  <strong>{url.replace(/-/g, ' ')}</strong>&quot;
                </p>
              ) : (
                <p className={cn(sd.searchInfo, sd.textCenter)}>
                  Não foram encontrados resultados <br />
                  para a sua busca: &quot;
                  <strong>{url.replace(/-/g, ' ')}</strong>&quot;
                </p>
              )}
              <ul className={sd.searchContainer}>
                {newArray.map(({ name, slug, items }, index) => (
                  <li
                    key={index}
                    className={items.length < 1 ? sd.invisible : ''}
                  >
                    <section>
                      <h2 style={{ color: '#7C0E6F' }}>
                        Principais Entregáveis {name}
                      </h2>
                      <ul>
                        {items.map((item, index) => (
                          <li key={index}>
                            <Link href={`/${slug}/${item.slug}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer location={location} />
    </>
  )
}

export default ResultadoBusca
